import directionDown from "@iconify/icons-wi/direction-down";
import directionDownLeft from "@iconify/icons-wi/direction-down-left";
import directionDownRight from "@iconify/icons-wi/direction-down-right";
import directionLeft from "@iconify/icons-wi/direction-left";
import directionRight from "@iconify/icons-wi/direction-right";
import directionUp from "@iconify/icons-wi/direction-up";
import directionUpLeft from "@iconify/icons-wi/direction-up-left";
import directionUpRight from "@iconify/icons-wi/direction-up-right";
import { IconifyIcon } from "@iconify/react";

/**
 * Возвращает массив [текущее время суток, последующее время суток],
 * например: [утро, день]
 *
 * @param {number} hour - текущий час суток
 * @returns {string} - строка
 */
export const getPartOfDay = (hour: number): Array<string> => {
  const res: Array<string> = [];
  const data = [
    [23, 5, "утром", "днём"],
    [5, 12, "днём", "вечером"],
    [12, 16, "вечером", "ночью"],
    [16, 23, "ночью", " утром"],
  ];

  for (let i = 0; i < data.length; i++) {
    if (hour >= data[i][0] && hour <= data[i][1]) {
      res.push(<string>data[i][2]);
      res.push(<string>data[i][3]);
    }
  }

  return res;
};

/**
 * Возвращает строку с первой заглавной буквой
 *
 * @param {string} str - строка
 * @returns {string} - строка
 */
export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const tempFormat = (temp: number): string => {
  let res = "";

  if (temp > 0) {
    res = `+${temp}${"°".replace(/[\n\r]+/g, "")}`;
  }
  if (temp <= 0) {
    res = `${temp}°`;
  }

  return res;
};

/**
 * Возвращает расшифровку значения атмосферного давления
 *
 * @param {string} str - значение давления
 * @param {string} outtype - тип вывода результата
 * @returns {string} - строка
 */
export const formatPressure = (str: number, outtype: string): string => {
  const PressureDescriptions = ["повышенное", "нормальное", "пониженное"];
  const currentPressure = (str / 1.333).toFixed(0);
  let res = "";

  if (outtype === "full") {
    if (Number(currentPressure) > 760)
      res = `${currentPressure} мм рт. ст. ${PressureDescriptions[0]}`;
    if (Number(currentPressure) === 760)
      res = `${currentPressure} мм рт. ст. ${PressureDescriptions[1]}`;
    if (Number(currentPressure) < 760)
      res = `${currentPressure} мм рт. ст. ${PressureDescriptions[2]}`;
  }
  if (outtype === "desc") {
    if (Number(currentPressure) > 760) res = PressureDescriptions[0];
    if (Number(currentPressure) === 760) res = PressureDescriptions[1];
    if (Number(currentPressure) < 760) res = PressureDescriptions[2];
  }
  if (outtype === "brief") {
    res = `${currentPressure} мм рт. ст. `;
  }
  if (outtype === "short") {
    return currentPressure;
  }

  return res;
};

/**
 * Возвращает расшифровку значения видимости
 *
 * @param {string} val - значение видимости
 * @returns {string} - строка
 */
export const formatVisibility = (val: number): string => {
  const VisibilityDescriptions = [
    "очень плохая",
    "плохая",
    "средняя",
    "хорошая",
    "очень хорошая",
    "исключительная",
  ];
  let res = "";

  if (val >= 0 && val <= 500) res = VisibilityDescriptions[0];
  if (val >= 501 && val <= 2000) res = VisibilityDescriptions[1];
  if (val >= 2001 && val <= 10000) res = VisibilityDescriptions[2];
  if (val >= 10001 && val <= 20000) res = VisibilityDescriptions[3];
  if (val >= 20001 && val <= 50000) res = VisibilityDescriptions[4];
  if (val > 50001) res = VisibilityDescriptions[5];

  return res;
};

/**
 * Возвращает расшифровку значения влажности
 *
 * @param {string} str - значение влажности
 * @returns {string} - строка
 */
export const formatHumidity = (str: number): string => {
  const HumidityDescriptions = ["повышенная", "нормальная", "пониженная"];
  let res = "";

  if (str > 65) res = HumidityDescriptions[0];
  if (str >= 35 && str <= 65) res = HumidityDescriptions[1];
  if (str < 35) res = HumidityDescriptions[2];

  return res;
};

/**
 * Возвращает расшифровку значения облачности
 *
 * @param {string} percent - значением облачности
 * @returns {string} - строка
 */
export const formatClouds = (percent: number): string => {
  const CloudsDescriptions = [
    "малооблачно",
    "рассеянные облака",
    "разбитые облака",
    "пасмурные облака",
  ];
  let res = "";

  if (percent <= 24) res = CloudsDescriptions[0];
  if (percent >= 25 && percent <= 50) res = CloudsDescriptions[1];
  if (percent >= 51 && percent <= 84) res = CloudsDescriptions[2];
  if (percent >= 85) res = CloudsDescriptions[3];

  return res;
};

/**
 * Возвращает расшифровку значения УФ-индекса
 *
 * @param {string} index - значением индекса
 * @returns {string} - строка
 */
export const formatUvi = (index: number): string => {
  const UviDescriptions = ["низкий", "средний", "высокий", "очень высокий", "экстремальный"];
  let res = "";

  if (index <= 2.5) res = UviDescriptions[0];
  if (index >= 2.5 && index <= 5.5) res = UviDescriptions[1];
  if (index >= 5.5 && index <= 7.5) res = UviDescriptions[2];
  if (index >= 7.5 && index <= 10.5) res = UviDescriptions[3];
  if (index >= 10.5) res = UviDescriptions[4];

  return res;
};

/**
 * Возвращает иконку направления ветра по градусам
 *
 * @param {number} deg - значением индекса
 * @returns {string} - строка
 */
export const calcWindDir = (deg: number): IconifyIcon => {
  const directions = [
    directionUp,
    directionUpRight,
    directionRight,
    directionDownRight,
    directionDown,
    directionDownLeft,
    directionLeft,
    directionUpLeft,
    directionUp,
  ];

  return directions[Math.round((deg % 360) / 45)];
};

/**
 * Возвращает расшифровку значения скорости ветра
 *
 * @param {number} speed - значением скорости ветра
 * @returns {string} - строка
 */
export const formatWindSpeedDescription = (speed: number): string => {
  let descriptions = "";

  if (speed === 0 && speed <= 0.2) descriptions = " штиль";
  if (speed >= 0.3 && speed <= 1.5) descriptions = " тихий ветер";
  if (speed >= 1.6 && speed <= 3.3) descriptions = " лёгкий бриз";
  if (speed >= 3.4 && speed <= 5.4) descriptions = " слабый бриз";
  if (speed >= 5.5 && speed <= 7.9) descriptions = " умеренный бриз";
  if (speed >= 8 && speed <= 10.7) descriptions = " свежий бриз";
  if (speed >= 10.8 && speed <= 13.8) descriptions = " сильный бриз";
  if (speed >= 13.9 && speed <= 17.1) descriptions = " крепкий ветер";
  if (speed >= 17.2 && speed <= 20.7) descriptions = " буря";
  if (speed >= 20.8 && speed <= 24.4) descriptions = " шторм";
  if (speed >= 24.5 && speed <= 28.4) descriptions = " сильный шторм";
  if (speed >= 28.5 && speed <= 32.6) descriptions = " жестокий шторм";
  if (speed >= 32.7) descriptions = " ураган";

  return descriptions;
};

/**
 * Возвращает приведение timestamp к виду "Пн, 31/12"
 *
 * @param {number} ts - timestamp
 * @param {boolean} checkHoliday - выводить только выходные дни "Сб, Вс"
 * @returns {string} - строка
 */
export const timestampToDate = (ts: number, checkHoliday: boolean): boolean | string => {
  const dayNames = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const date = new Date(ts * 1000);
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);

  if (checkHoliday) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return `${dayNames[date.getDay()]}, ${day}/${month}`;
};

/**
 * Возвращает приведение timestamp к виду "09:43"
 *
 * @param {number} ts - timestamp
 * @returns {string} - строка
 */
export const timestampToTime = (ts: number): string => {
  const date = new Date(ts * 1000);

  return `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;
};

/**
 * Возвращает приведение timestamp к виду "31/12/20 15:56"
 *
 * @param {number} ts - timestamp
 * @returns {string} - строка
 */
export const dateForCopyrights = (ts: number): string => {
  const date = new Date(ts);
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  const time = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;

  return `${day}/${month}/${year} ${time}`;
};
