export const OPENWEATHERMAP_API_URL = "https://api.openweathermap.org/data/2.5/onecall?";
// #REACT_APP_OPENWEATHERMAP_API_KEY=f88aad4923b7f5db5411360d12d3b987 my
// #REACT_APP_OPENWEATHERMAP_API_KEY=becd4aa142f0f707efaf65c62f74181a
export const OPENWEATHERMAP_API_KEY = "1d9b6c50733d287c57dfc2735b22f6f8";

// # Monastirshina, Russia
export const LAT = "54.3455";
export const LNG = "31.8367";

export const UNITS = "metric";
export const LANG = "ru";
