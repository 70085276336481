/* ------------         REDUCER         ------------------ */
import { IActionAttributes } from "../../Interfaces/IActionAttributes";
import { CLEAR_ERROR, SET_ERROR } from "../actions/errorsAction";

export const error = (state = [], action: IActionAttributes): string[] => {
  switch (action.type) {
    case SET_ERROR:
      return [...state, action.payload];

    case CLEAR_ERROR:
      return [];

    default:
      return state;
  }
};
