import * as React from "react";
import { WeatherIcon } from "../../icons";
import { ICurrentTypes, IHourlyItemsTypes } from "../../Interfaces/IRootState";
import { getPartOfDay, tempFormat } from "../../utils";
import s from "./CurrentWeatherBlock.module.css";

export const CurrentWeatherBlock = (props: {
  current: ICurrentTypes;
  hourly: IHourlyItemsTypes;
}): JSX.Element => {
  const currentHour = new Date().getHours();
  const { current, hourly } = props;
  const temp1ofDay = Math.round(hourly[9].temp);
  const temp2ofDay = Math.round(hourly[15].temp);
  const times1OfDay = getPartOfDay(currentHour)[0];
  const times2OfDay = getPartOfDay(currentHour)[1];
  const currentTemp = Number(current.temp.toFixed(0));

  return (
    <div className={`${s.current_weather} card`}>
      <div className={s.current_weather__icon}>
        <WeatherIcon name={current.weather[0].icon} />
      </div>
      <div
        className={
          currentTemp < 0
            ? `${s.current_weather__temperature} blue`
            : `${s.current_weather__temperature} red`
        }
      >
        {currentTemp > 0 ? `+${currentTemp}` : `${currentTemp}`}°
      </div>
      <div className={s.current_weather__description}>{current.weather[0].description}</div>
      <div className={s.current_weather_feels_like__temperature}>
        <div>
          Ощущается как:
          {` ${tempFormat(Number(current.feels_like.toFixed(1)))}`}
        </div>
      </div>
      <div className={s.weather_of_the_day}>
        <div className={s.first_half_of_the_day}>
          <div className={s.first_half_of_the_day__name}>{times1OfDay}</div>
          <div className={s.first_half_of_the_day__icon}>
            <WeatherIcon name={hourly[9].weather[0].icon} />
          </div>
          <div
            className={
              temp1ofDay <= 0
                ? `${s.first_half_of_the_day__temperature} blue`
                : `${s.first_half_of_the_day__temperature} red`
            }
          >
            {tempFormat(temp1ofDay)}
          </div>
          <div className={s.first_half_of_the_day__description}>
            {hourly[9].weather[0].description}
          </div>
        </div>
        <div className={s.second_half_of_the_day}>
          <div className={s.second_half_of_the_day__name}>{times2OfDay}</div>
          <div className={s.second_half_of_the_day__icon}>
            <WeatherIcon name={hourly[15].weather[0].icon} />
          </div>
          <div
            className={
              temp2ofDay <= 0
                ? `${s.second_half_of_the_day__temperature} blue`
                : `${s.second_half_of_the_day__temperature} red`
            }
          >
            {tempFormat(temp2ofDay)}
          </div>
          <div className={s.second_half_of_the_day__description}>
            {hourly[15].weather[0].description}
          </div>
        </div>
      </div>
    </div>
  );
};
