import React from "react";

const defaultIconURL = "https://openweathermap.org/img/wn/";

export const WeatherIcon = (props: Record<string, string>): JSX.Element => {
  const { name, className } = props;
  const imageURL = `${defaultIconURL}${name}@2x.png`;

  return <img src={imageURL} className={className} alt={name} />;
};
