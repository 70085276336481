import axios from "axios";
import { now } from "moment/moment";
import { Dispatch } from "redux";
import { LANG, OPENWEATHERMAP_API_KEY, OPENWEATHERMAP_API_URL, UNITS } from "../../constants/intex";
import { IActionAttributes } from "../../Interfaces/IActionAttributes";
import { errorHandler } from "../actions/errorsAction";
import { FETCH_WEATHERDATA_SUCCESS, fetchWeatherDataSuccess } from "../actions/weatherAction";

// eslint-disable-next-line
const weatherData = (state = { isLoading: false }, action: any) => {
  switch (action.type) {
    case FETCH_WEATHERDATA_SUCCESS:
      return {
        ...state,
        ...action.weatherData,
        isLoading: false,
        isWeatherLoaded: true,
        fetchDataTime: now(),
      };

    default:
      return state;
  }
};

export const fetchWeather =
  (coords: { lat: string; lng: string }) =>
  async (dispatch: Dispatch<IActionAttributes>): Promise<void> => {
    const { lat, lng } = coords;

    await axios
      .get(
        `${OPENWEATHERMAP_API_URL}lat=${lat}&lon=${lng}&appid=${OPENWEATHERMAP_API_KEY}&units=${UNITS}&lang=${LANG}`
      )
      .then((res) => dispatch(fetchWeatherDataSuccess(res.data)))
      .catch((err) => dispatch(errorHandler(`fetchWeather: ${err.message}`)));
  };

export default weatherData;
