import React, { useCallback, useEffect } from "react";

import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import { CurrentDescriptionBlock } from "./Components/CurrentDescriptionBlock/CurrentDescriptionBlock";
import { CurrentWeatherBlock } from "./Components/CurrentWeatherBlock/CurrentWeatherBlock";
import { DateTimeBlock } from "./Components/DateTimeBlock/DateTimeBlock";
import { ForecastBlock } from "./Components/ForecastBlock/ForecastBlock";
import { LoadingIndicator } from "./Components/Loader/Loader";
import { SiteInfoBlock } from "./Components/SiteInfoBlock/SiteInfoBlock";
import { LAT, LNG } from "./constants/intex";
import { IRootState } from "./Interfaces/IRootState";
import { fetchWeather } from "./redux/reducers/weatherData";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const weatherDataSelector = useSelector((state: IRootState) => state.weatherData);
  console.count("+");
  const getGeoLoc = useCallback(() => {
    dispatch(
      fetchWeather({
        lat: LAT,
        lng: LNG,
      })
    );

    const intervalFetchWeather = setInterval(() => {
      dispatch(
        fetchWeather({
          lat: LAT,
          lng: LNG,
        })
      );
    }, 30 * 60 * 1000);

    return () => {
      clearInterval(intervalFetchWeather);
    };
  }, []);

  useEffect(() => {
    getGeoLoc();
  }, [getGeoLoc]);

  return (
    <Container>
      {weatherDataSelector.isWeatherLoaded ? (
        <div className="grid-container">
          <DateTimeBlock />
          <CurrentWeatherBlock
            current={weatherDataSelector.current}
            hourly={weatherDataSelector.hourly}
          />
          <CurrentDescriptionBlock />
          <ForecastBlock />
          <SiteInfoBlock data={weatherDataSelector.fetchDataTime} />
        </div>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  );
};
