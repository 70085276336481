import React from "react";

import { render } from "react-dom";
import { Provider } from "react-redux";
import "typeface-roboto";
import { App } from "./App";
import store from "./redux";

render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
