import React, { useState } from "react";
import "./Tooltip.css";

function Tooltip(props: {
  delay?: number;
  children: JSX.Element;
  content: string;
  direction: string;
}): JSX.Element {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);
  const { delay, children, content, direction } = props;

  const showTip = (): void => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = (): void => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={`Tooltip-Tip ${direction || "top"}`}>{content}</div>}
    </div>
  );
}

export default Tooltip;
