import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils";
import s from "./DateTime.module.css";

export const DateTimeBlock = (): JSX.Element => {
  const [date, setDate] = useState(new Date());

  const tick = (): void => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return (): void => {
      clearInterval(timerID);
    };
  }, []);

  const day = date.toLocaleString("ru", { day: "2-digit", month: "long" });
  const weekday = capitalizeFirstLetter(date.toLocaleString("ru", { weekday: "long" }));

  return (
    <div className={`${s.date_time} card`}>
      <div className={s.date_time__time}>{date.toLocaleTimeString("ru", { hour12: false })}</div>
      <div className={s.date_time__date}>{day}</div>
      <div className={s.date_time__weekday}>{weekday}</div>
    </div>
  );
};
