import { combineReducers } from "redux";

import { error } from "./error";
import weatherData from "./weatherData";

const rootReducer = combineReducers({
  error,
  weatherData,
});

export default rootReducer;
