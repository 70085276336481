import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    store: Store;
  }
}

const composeEnhancers =
  typeof window === "object" && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== "undefined"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middleware = [thunk];

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(rootReducer, enhancer);

window.store = store;

export default store;
