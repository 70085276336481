import React from "react";
import { ThreeCircles } from "react-loader-spinner";

export const LoadingIndicator = (): JSX.Element => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      color: "black",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ThreeCircles color="white" height={110} width={110} ariaLabel="three-circles-rotating" />
  </div>
);
