import React from "react";
import { dateForCopyrights } from "../../utils";

interface IProps {
  data: number;
}

export const SiteInfoBlock = (props: IProps): JSX.Element => {
  const { data } = props;
  const appVersion = process.env.REACT_APP_VERSION;

  return (
    <div className="site-info card">
      <span>
        &copy; Copyright 2010 -{new Date().getFullYear()}{" "}
        <a href="https://listratenkov.com">Listratenkov.com</a> - All Rights Reserved.
      </span>
      <span>
        Версия - v.{appVersion} - Последнее обновление : {dateForCopyrights(data)}
      </span>
    </div>
  );
};
