import React from "react";

import dewpointIcon from "@iconify-icons/carbon/dew-point";
import dayIcon from "@iconify-icons/whh/day";
import nightIcon from "@iconify-icons/wi/night-clear";
import barometerIcon from "@iconify/icons-wi/barometer";
import humidityIcon from "@iconify/icons-wi/humidity";
import snowIcon from "@iconify/icons-wi/snow";
import umbrellaIcon from "@iconify/icons-wi/umbrella";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { WeatherIcon } from "../../icons";
import { IRootState } from "../../Interfaces/IRootState";
import { calcWindDir, formatPressure, tempFormat, timestampToDate } from "../../utils";
import Tooltip from "../Tooltip/Tooltip";

export function ForecastBlock(): JSX.Element {
  const { daily } = useSelector((state: IRootState) => state.weatherData);

  return (
    <div className="forecast">
      {daily.slice(1, 2).map((item, index) => (
        <div key={item.dt} className={`forecast__day forecast__day${index + 1} card`}>
          <div
            className={
              timestampToDate(item.dt, true)
                ? `forecast__day${index + 1}__date red`
                : `forecast__day${index + 1}__date white`
            }
          >
            Завтра, {timestampToDate(item.dt, false)}
          </div>
          <div className={`forecast__day${index + 1}__icon`}>
            <WeatherIcon name={item.weather[0].icon} />
          </div>
          <div className={`forecast__day${index + 1}__temperature`}>
            <div
              className={
                item.temp.day <= 0
                  ? `forecast__day${index + 1}__temperature-day blue`
                  : `forecast__day${index + 1}__temperature-day red`
              }
            >
              <Tooltip content="Днём" direction="top">
                <Icon icon={dayIcon} />
              </Tooltip>
              {tempFormat(Number(item.temp.day.toFixed(0)))}
            </div>
            <div
              className={
                item.temp.night <= 0
                  ? `forecast__day${index + 1}__temperature-night blue`
                  : `forecast__day${index + 1}__temperature-night red`
              }
            >
              <Tooltip content="Ночью" direction="top">
                <Icon icon={nightIcon} />
              </Tooltip>
              {tempFormat(Number(item.temp.night.toFixed(0)))}
            </div>
          </div>
          <div className={`forecast__day${index + 1}__description`}>
            {item.weather[0].description}
          </div>
          <div className={`forecast__day${index + 1}__dewpoint`}>
            <Tooltip content="Точка росы" direction="top">
              <Icon icon={dewpointIcon} />
            </Tooltip>{" "}
            {item.dew_point}
          </div>
          <div className={`forecast__day${index + 1}__pressure`}>
            <Tooltip content="Атмосферное давление" direction="top">
              <Icon icon={barometerIcon} />
            </Tooltip>{" "}
            {`${formatPressure(item.pressure, "brief")}`}
          </div>
          <div className={`forecast__day${index + 1}__humidity`}>
            <Tooltip content="Относительная влажность" direction="top">
              <Icon icon={humidityIcon} />
            </Tooltip>
            {`${item.humidity}%`}
          </div>
          <div className={`forecast__day${index + 1}__wind`}>
            <Tooltip content="Направление и скорость ветра" direction="top">
              <Icon icon={calcWindDir(item.wind_deg)} />
            </Tooltip>
            {` ${item.wind_speed.toFixed(1)} м/с`}
          </div>
          <div className={`forecast__day${index + 1}__rain`}>
            {item.rain ? (
              <Tooltip content="Дождь" direction="top">
                <Icon icon={umbrellaIcon} />
              </Tooltip>
            ) : (
              ""
            )}
            {item.rain ? `${item.rain} мм` : ""}
            {item.snow ? (
              <Tooltip content="Снег" direction="top">
                <Icon icon={snowIcon} />
              </Tooltip>
            ) : (
              ""
            )}
            {item.snow ? `${item.snow} мм` : ""}
          </div>
        </div>
      ))}
      {daily.slice(2, daily.length).map((item, index) => (
        <div key={item.dt} className={`forecast__day forecast__day${index + 2} card`}>
          <div
            className={
              timestampToDate(item.dt, true)
                ? `forecast__day${index + 2}__date red`
                : `forecast__day${index + 2}__date white`
            }
          >
            {timestampToDate(item.dt, false)}
          </div>
          <div className={`forecast__day${index + 2}__icon`}>
            <WeatherIcon name={item.weather[0].icon} />
          </div>
          <div className={`forecast__day${index + 2}__temperature`}>
            <div
              className={
                item.temp.day <= 0
                  ? `forecast__day${index + 2}__temperature-day blue`
                  : `forecast__day${index + 2}__temperature-day red`
              }
            >
              <Tooltip content="Днём" direction="top">
                <Icon icon={dayIcon} />
              </Tooltip>
              {tempFormat(Number(item.temp.day.toFixed(0)))}
            </div>
            <div
              className={
                item.temp.night <= 0
                  ? `forecast__day${index + 2}__temperature-night blue`
                  : `forecast__day${index + 2}__temperature-night red`
              }
            >
              <Tooltip content="Ночью" direction="top">
                <Icon icon={nightIcon} />
              </Tooltip>
              {tempFormat(Number(item.temp.night.toFixed(0)))}
            </div>
          </div>
          <div className={`forecast__day${index + 2}__description`}>
            {item.weather[0].description}
          </div>
          <div className={`forecast__day${index + 2}__dewpoint`}>
            <Tooltip content="Точка росы" direction="top">
              <Icon icon={dewpointIcon} />
            </Tooltip>{" "}
            {item.dew_point}
          </div>
          <div className={`forecast__day${index + 2}__pressure`}>
            <Tooltip content="Атмосферное давление" direction="top">
              <Icon icon={barometerIcon} />
            </Tooltip>{" "}
            {`${formatPressure(item.pressure, "brief")}`}
          </div>
          <div className={`forecast__day${index + 2}__humidity`}>
            <Tooltip content="Относительная влажность" direction="top">
              <Icon icon={humidityIcon} />
            </Tooltip>
            {`${item.humidity}%`}
          </div>
          <div className={`forecast__day${index + 2}__wind`}>
            <Tooltip content="Направление и скорость ветра" direction="top">
              <Icon icon={calcWindDir(item.wind_deg)} />
            </Tooltip>
            {` ${item.wind_speed.toFixed(1)} м/с`}
          </div>
          <div className={`forecast__day${index + 2}__rain`}>
            {item.rain ? (
              <Tooltip content="Дождь" direction="top">
                <Icon icon={umbrellaIcon} />
              </Tooltip>
            ) : (
              ""
            )}
            {item.rain ? `${item.rain} мм` : ""}
            {item.snow ? (
              <Tooltip content="Снег" direction="top">
                <Icon icon={snowIcon} />
              </Tooltip>
            ) : (
              ""
            )}
            {item.snow ? `${item.snow} мм` : ""}
          </div>
        </div>
      ))}
    </div>
  );
}
