/* -----------------    ACTION TYPES    ------------------ */
import { IRootState } from "../../Interfaces/IRootState";

export const FETCH_WEATHERDATA_SUCCESS = "FETCH_WEATHERDATA_SUCCESS";
export const FETCH_OTHERDATA_SUCCESS = "FETCH_OTHERDATA_SUCCESS";

/* ------------    ACTION CREATORS      ------------------ */
export const fetchWeatherDataSuccess = (
  weatherData: IRootState
): { weatherData: IRootState; type: string } => ({
  type: FETCH_WEATHERDATA_SUCCESS,
  weatherData,
});
export const fetchOtherDataSuccess = (
  weatherData: IRootState
): { weatherData: IRootState; type: string } => ({
  type: FETCH_OTHERDATA_SUCCESS,
  weatherData,
});

export const actions = {
  fetchWeatherDataSuccess,
  fetchOtherDataSuccess,
};
